
// DO NOT EDIT THIS FILE DIRECTLY.
// Update it by running "npm run themes:generate"
@use './app';

:root {
  --font-family-default: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-mono: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --font-size-xs: 0.75rem;
  --font-size-sm: 0.875rem;
  --font-size-fluid-x2: clamp(0.79rem, calc(0.76rem + 0.17vw), 0.89rem);
  --font-size-fluid-x1: clamp(0.89rem, calc(0.85rem + 0.19vw), 1rem);
  --font-size-fluid-0: clamp(1rem, calc(0.96rem + 0.22vw), 1.13rem);
  --font-size-fluid-1: clamp(1.13rem, calc(1.08rem + 0.24vw), 1.27rem);
  --font-size-fluid-2: clamp(1.27rem, calc(1.21rem + 0.28vw), 1.42rem);
  --font-size-fluid-3: clamp(1.42rem, calc(1.36rem + 0.31vw), 1.6rem);
  --font-size-fluid-4: clamp(1.6rem, calc(1.53rem + 0.35vw), 1.8rem);
  --font-size-fluid-5: clamp(1.8rem, calc(1.72rem + 0.39vw), 2.03rem);
  --font-size-fluid-6: clamp(2.03rem, calc(1.94rem + 0.44vw), 2.28rem);
  --font-size-fluid-7: clamp(2.28rem, calc(2.18rem + 0.5vw), 2.57rem);
  --font-size-fluid-8: clamp(2.57rem, calc(2.45rem + 0.56vw), 2.89rem);
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --rounded-sm: 2px;
  --rounded-md: 4px;
  --rounded-lg: 8px;
  --rounded-full: 9999px;
  --shadow-xs: 0 1px 3px rgba(100, 100, 100, 0.09);
  --shadow-sm: 0 1px 5px rgba(100, 100, 100, 0.05);
  --shadow-md: 0 0 0 1px var(--color-neutral-border), 0 1px 5px rgba(100, 100, 100, 0.05), 0 0 40px rgba(100, 100, 100, 0.015);
  --shadow-lg: 0 0 0 1px var(--color-neutral-border), 0 5px 17px rgba(100, 100, 100, 0.14);
  --shadow-xl: 0 4px 12px rgba(100, 100, 100, 0.15), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --shadow-xxl: 0 24px 38px 3px rgba(100, 100, 100, 0.16), 0 9px 86px 8px rgba(100, 100, 100, 0.1), 0 11px 15px -7px rgba(100, 100, 100, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.05);
  --space-0: 0px;
  --space-1: 4px;
  --space-2: 8px;
  --space-3: 12px;
  --space-4: 16px;
  --space-5: 24px;
  --space-6: 36px;
  --space-7: 48px;
  --space-8: 64px;
  --color-backdrop: var(--light, rgba(0, 0, 0, 0.25)) var(--dark, rgba(0, 0, 0, 0.5));
  --color-background: var(--light, #fff) var(--dark, #111113);
  --color-surface: var(--light, #fff) var(--dark, #111113);
  --color-primary-mark: var(--light, #00a1d841) var(--dark, #1c9dfd5d);
  --color-primary-bg-subtle: var(--light, #f1fafd) var(--dark, #111a27);
  --color-primary-bg: var(--light, #e1f6fd) var(--dark, #112840);
  --color-primary-bg-hover: var(--light, #d1f0fa) var(--dark, #113555);
  --color-primary-bg-active: var(--light, #bee7f5) var(--dark, #154467);
  --color-primary-line-weak: var(--light, #bee7f5) var(--dark, #154467);
  --color-primary-line: var(--light, #a9daed) var(--dark, #1b537b);
  --color-primary-border: var(--light, #8dcae3) var(--dark, #1f6692);
  --color-primary-border-hover: var(--light, #60b3d7) var(--dark, #197cae);
  --color-primary-focus-ring: var(--light, #60b3d7) var(--dark, #197cae);
  --color-primary-bg-solid: var(--light, #7ce2fe) var(--dark, #7ce2fe);
  --color-primary-bg-solid-hover: var(--light, #74daf8) var(--dark, #a8eeff);
  --color-primary-text: var(--light, #00749e) var(--dark, #75c7f0);
  --color-primary-text-contrast: var(--light, #1d3e56) var(--dark, #c2f3ff);
  --color-primary-placeholder: var(--light, #7ce2fe) var(--dark, #7ce2fe);
  --color-neutral-bg-subtle: var(--light, #f9f9fb) var(--dark, #18191b);
  --color-neutral-bg: var(--light, #f0f0f3) var(--dark, #212225);
  --color-neutral-bg-hover: var(--light, #e8e8ec) var(--dark, #272a2d);
  --color-neutral-bg-active: var(--light, #e0e1e6) var(--dark, #2e3135);
  --color-neutral-line-weak: var(--light, #e0e1e6) var(--dark, #2e3135);
  --color-neutral-line: var(--light, #d9d9e0) var(--dark, #363a3f);
  --color-neutral-border: var(--light, #cdced6) var(--dark, #43484e);
  --color-neutral-border-hover: var(--light, #b9bbc6) var(--dark, #5a6169);
  --color-neutral-focus-ring: var(--light, #b9bbc6) var(--dark, #5a6169);
  --color-neutral-bg-solid: var(--light, #8b8d98) var(--dark, #696e77);
  --color-neutral-bg-solid-hover: var(--light, #80838d) var(--dark, #777b84);
  --color-neutral-text: var(--light, #60646c) var(--dark, #b0b4ba);
  --color-neutral-text-contrast: var(--light, #1c2024) var(--dark, #edeef0);
  --color-neutral-placeholder: var(--light, #8b8d98) var(--dark, #696e77);
  --callout-blue-text: var(--light, #006dcbf2) var(--dark, #70b8ff);
  --callout-blue-bg: var(--light, #008ff519) var(--dark, #0077ff3a);
  --callout-mint-text: var(--light, #007763fd) var(--dark, #67ffded2);
  --callout-mint-bg: var(--light, #00d29e22) var(--dark, #00fff61d);
  --callout-green-text: var(--light, #00713fde) var(--dark, #46fea5d4);
  --callout-green-bg: var(--light, #00a43319) var(--dark, #22ff991e);
  --callout-orange-text: var(--light, #cc4e00) var(--dark, #ffa057);
  --callout-orange-bg: var(--light, #ff9c0029) var(--dark, #fb6a0025);
  --callout-red-text: var(--light, #c40006d3) var(--dark, #ff9592);
  --callout-red-bg: var(--light, #f3000d14) var(--dark, #ff173f2d);
  --callout-purple-text: var(--light, #52009aba) var(--dark, #d19dff);
  --callout-purple-bg: var(--light, #8e00f112) var(--dark, #c150ff2d);
  --callout-gray-text: var(--light, #0000009b) var(--dark, #ffffffaf);
  --callout-gray-bg: var(--light, #00000006) var(--dark, #ffffff09)
};

